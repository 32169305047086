.App {
border-top: 5px solid #2bedf7;
border-bottom: 15px solid white;
  text-align: center;

  height: 95vh;

}
body, html, * {
  font-family: 'Share Tech Mono', monospace;
}

.p-terminal-input {
  color: white;
  font-weight: bold;
  font-size: 25px !important;
}



.p-terminal-response {
  padding: 15px;
  background-color: #000222;
  border: 1px solid white;
  border-radius: 10px;
  margin: 10px
}

body {
  /* background-image: url('./images/bg8.webp'); */
  background-color: #000222;
}
.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

img {
  max-width: 100%;
  height: auto;
}

/* Basic Flexbox layout for responsiveness */
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Responsive header styling */
.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .app-header {
    padding: 10px;
  }

  .app-main {
    width: 100%;
    padding: 10px;
  }
  #terminalwrapper {
    width: 100%;
  }
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* all links are white and cyan and bolded on hover */
a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  color: cyan;
}